tr.collapse {
  &:hover {
    background-color: inherit;
  }
}

.dv-collapse-label {
  cursor: default;
}

td.dv-table-collapse-cell {
  padding: 0;
}

tr[data-toggle="collapse"]:not(.collapsed) i.dv-collapse-carrot {
    transform: rotate(180deg);
}

tr.dv-clickable-row {
  cursor: pointer;
}
