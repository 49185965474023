.dv-side-nav {
  .dv-nav-item {
    border-bottom: solid 1px $gray-400;
    padding: 0.3rem 1rem;

    &:hover {
      background-color: #FBF6BA;
    }

    &.active {
      background-color: $ua-green;

      .dv-nav-link {
        color: white;
      }

      &:hover {
        background-color: $ua-green;
      }
    }

    .dv-nav-link {
      color: $gray-800;
      text-decoration: none;
      width: 100%;
      display: block;
    }
  }
}
