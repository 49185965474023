.input-group {
  //font-awesome has a display of in-block. Needs to be flex to work without flex display input groups
  .fa {
    display: flex;
    padding: 0 1rem;
  }
}

.dv-required:after {
  content: "∗";
  color: $danger;
  padding-left: 0.5rem;
}

// For .NET MVC/Core apps that use HtmlHelpers or Razor Tag Helpers to build form elements.
// The .NET framework was built with Bootstrap 3 and below, which had this class.
// Bootstrap 4 renamed it to is-invalid, so we clone it.
// https://stackoverflow.com/a/64363767
.input-validation-error {
  @extend .is-invalid;
}

.dv-giant-search {
  background-color: $ua-gold;
  padding: 1rem;
  width: 80%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 0;
  font-size: 1.5rem;

  input {
    background-color: $ua-gold;
    color: $body-color;
    font-weight: 300;
    border: 0;
    outline: 0;
    border-radius: 0;
    width: 100%;
    &:focus {
      border: 0;
      outline: 0;
    }
    &::placeholder {
      color: $body-color;
    }
  }
}
