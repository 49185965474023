
.navbar-brand {
  font-size: 1.875rem;
  font-weight: 300;
  color: $ua-gold;
  text-decoration: none;
  @include media-breakpoint-down(lg) {
    font-size: 1.5rem;
  }
  &:hover {
    color: $ua-gold;
    text-decoration: none;
  }
}

.dropdown-menu {
  //updating styling for forms in dropdown menus
  form {
    margin: 0;
    padding: 0;

    input[type=submit] {
      cursor: pointer;
    }
  }
}

.dv-menu {
  background: white;
  box-shadow: 0 10px 9px rgba(0,0,0,.2);

  .nav-item {
    margin: 0 1rem;
    &:hover .nav-link {
      border-bottom-color: $body-color;
    }
  }

  .nav-link {
    color: $body-color !important;
    font-size: 1.125rem;
    font-weight: 500;
    border-bottom: 2px solid transparent;
    padding: 0.5rem 0 0.1rem !important;
  }
  .navbar-toggler {
    align-self: auto;
  }


  .navbar-nav {

    li {
      border-top: 2px solid transparent;

      &:hover {
        border-top: 2px solid white;
      }

      &:not(:last-child) {
        margin-right: 0.9375rem;
      }
    }

    .dropdown-menu {
      border-radius: 0;
      padding: 0;
      border-width: 0;
      box-shadow: 0 10px 9px rgba(0,0,0,.2);

      .dropdown-item {
        padding: 0.9375rem;

        &:hover {
          background: $gray-400;
        }
      }
    }
  }
}
