/**
 * Contains styling for the main layout page. And other general layout styling
 */

html {
  height: 100%;
}

body {
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  padding: 2.813rem 0;
  flex: 1 0 auto;
}

@media (max-width: 575.98px) {
  .content {
    padding: 1rem 0;
  }
}

.dv-header, .dv-footer {
  flex: 0 0 auto;
}

.dv-ua-logo {
  margin: 1rem 1rem 1rem 0;
  background-size: 100% 100%;
}

.dv-ua-logo.d-none.d-lg-block {
  width: 12rem;
  height: 3rem;
  background-image: url('../img/UA_Logo_WHT_RGB.svg')
}

.dv-ua-logo.d-lg-none {
  width: 12rem;
  height: 3rem;
  background-image: url('../img/UA_Logo_WHT_RGB.svg')
}

/** Header Styles */
.dv-header {
  .dv-blade {
    background: $ua-green;

    #navSearchButton.show #searchIcon:before {
      content: "";
      display: inline-block; min-width: 1.5rem;

    }
    #navSearchButton.show #vEllipsis {
      display: none;
    }

    #megaNav {
      box-shadow: 0 10px 9px rgba(0,0,0,.2);
    }
    ul.dv-quick-links {
      line-height: 1rem;
      list-style: none;

      li {
        margin: 1.25rem 0;

        a {
          color: $body-color;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .container {
    width: 100%;
    max-width: none;
  }
}
