/* Light (300) */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local(''),
  url('../fonts/roboto-v27-latin-300.woff2') format('woff2')
}

/* Light Italic (300) */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: local(''),
  url('../fonts/roboto-v27-latin-300italic.woff2') format('woff2')
}

/* Regular (400) */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local(''),
  url('../fonts/roboto-v27-latin-regular.woff2') format('woff2')
}

/* Regular Italic (400) */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local(''),
  url('../fonts/roboto-v27-latin-italic.woff2') format('woff2')
}
/* Medium (500)*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local(''),
  url('../fonts/roboto-v27-latin-500.woff2') format('woff2');
}
/* Medium Italic (500) */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  src: local(''),
  url('../fonts/roboto-v27-latin-500italic.woff2') format('woff2')
}
/* Bold (700) */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local(''),
  url('../fonts/roboto-v27-latin-700.woff2') format('woff2')
}

/* Bold Italic (700) */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local(''),
  url('../fonts/roboto-v27-latin-700italic.woff2') format('woff2')
}
